import axiosClient from "@/config/axios";
import { Collection } from "@/interfaces/Collection";
import { ref } from "vue";

const collections = ref<Collection[]>(JSON.parse(localStorage.getItem('collections') ?? '[]'))
const isCollectionsLoading = ref(false)

export function useCollection() {
  function loadCollections() {
    if (!collections.value.length) {
      isCollectionsLoading.value = true 

      axiosClient.get('/collections')
        .then((res) => {
          collections.value = res.data
          localStorage.setItem('collections', JSON.stringify(res.data))
        })
        .finally(() => isCollectionsLoading.value = false)
    }
  }

  return {
    collections,
    loadCollections,
    isCollectionsLoading
  }
}