<template>
  <main v-if="!isEcommerceLoading && !isCategoriesLoading && !isCollectionsLoading && !isProductsLoading">
    <PinkThemeHeader />
    <PinkThemeNav />
    <slot />
    <FloatingButton />
    <PinkThemeFooter />
  </main>
  <Loader v-else />
</template>

<script setup lang="ts">
import PinkThemeHeader from './components/PinkThemeHeader.vue';
import PinkThemeNav from './components/PinkThemeNav.vue';
import PinkThemeFooter from './components/PinkThemeFooter.vue';
import FloatingButton from './components/FloatingButton.vue';
import Loader from '@/components/loaders/Loader.vue';
import { onMounted } from 'vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from '@/composables/useProduct';
import { useEcommerce } from '@/composables/useEcommerce';

const { loadEcommerce, isEcommerceLoading, ecommerce } = useEcommerce()
const { loadCategories, isCategoriesLoading } = useCategory()
const { loadCollections, isCollectionsLoading } = useCollection()
const { loadProducts, isProductsLoading } = useProduct()

onMounted(() => {
  loadEcommerce()
  loadCategories()
  loadCollections()
  loadProducts()
})
</script>

<style lang="scss">
@import './../../../../assets/css/main.css';

$primary-color: v-bind("ecommerce?.visual?.colorPrimary ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'");
$menu-color: v-bind("ecommerce?.visual?.colorMenu ?? 'rgb(31 41 55 / var(--tw-bg-opacity))'");
$footer-color: v-bind("ecommerce?.visual?.footerColor ?? 'rgb(31 41 55 / var(--tw-bg-opacity))'");

main {
  nav {
    background: $menu-color !important
  }
}
.bottom-footer {
  background-color: $footer-color !important;
}
.bg-primary { 
  background: $primary-color
}
.border-primary {
  border-color: $primary-color
}
.text-primary {
  color: $primary-color
}
.hover\:text-primary:hover {
  color: $primary-color
}
.simple-spinner .loader {
  border: 5px solid $primary-color
}
</style>