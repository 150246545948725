import axiosClient from "@/config/axios";
import { Product } from "@/interfaces/products/Product";
import { ref } from "vue";
import { useCategory } from "./useCategory";

const products = ref<Product[]>(JSON.parse(localStorage.getItem('products') ?? '[]'))
const isProductsLoading = ref(false)

const { categories } = useCategory()

function defineCategoryProducts() {
  categories.value.map((category) => {
    category.products = products.value?.filter((p) => p.categoryId === category.id)

    return category
  })
}

export function useProduct() {
  function loadProducts() {
    if (!products.value?.length) {
      isProductsLoading.value = true 

      axiosClient.get('/products')
        .then((res) => {
          products.value = res.data
          localStorage.setItem('products', JSON.stringify(res.data))

          defineCategoryProducts()
        })
        .finally(() => isProductsLoading.value = false)
    } else {
      defineCategoryProducts()
    }
  }

  return {
    products,
    loadProducts,
    isProductsLoading
  }
}