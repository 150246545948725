import { ref } from "vue"

interface CartItem {
  uuid: string
  quantity: number
}

const cart = ref<CartItem[]>(JSON.parse(localStorage.getItem('cart') ?? '[]'))

export function useCart() {
  function addIntoCart(uuid: string, quantity: number) {
    const itemExists = cart.value.findIndex((i) => i.uuid === uuid)

    if (itemExists !== -1) {
      cart.value[itemExists].quantity += quantity
    } else {
      cart.value.push({
        uuid,
        quantity
      })
    }

    localStorage.setItem('cart', JSON.stringify(cart.value))
  }

  function removeFromCart(uuid: string) {
    cart.value = cart.value.filter((item) => item.uuid !== uuid)

    localStorage.setItem('cart', JSON.stringify(cart.value))
  }

  return  {
    cart,
    addIntoCart,
    removeFromCart
  }
}